<template>
  <div>
    <el-card style="width: 55%;">
      <div>
        <div style="width: 100%;display: flex;justify-content: space-between">
          <span>
            <el-icon><Document /></el-icon>
            <strong>&nbsp;兑换码</strong>
          </span>
        </div>

        <div style="margin-top: 30px;">
          <el-button type="primary" @click="handleAdd">生成</el-button>
        </div>

        <el-table stripe :data="redeemCodeList" style="width: 100%;min-height: 550px;margin-top: 2%;" empty-text="暂无数据">
          <el-table-column prop="code" label="兑换码" width="300" />
          <el-table-column prop="createTime" label="创建时间"  />
          <el-table-column label="状态">
            <template #default="scope">
              <el-tag
                  :type="scope.row.status == '1' ? 'danger' : 'success'"
                  disable-transitions
              >{{ scope.row.status == '1' ? '已使用' : '未使用' }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" >

            <template #default="scope">

              <el-button
                  type="danger"

                  @click="handleDelete(scope.row)"
                  icon="Delete"
              >
                删除
              </el-button>

            </template>

          </el-table-column>
        </el-table>

      </div>
    </el-card>

    <el-dialog
        v-model="openGameType"
        :title="title"
        width="30%"
    >

      <el-form :model="form" label-width="120px">

        <el-form-item label="生成数量">
          <el-input-number v-model="form.count" :min="1" :max="50" />
        </el-form-item>

      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="openGameType = false">取消</el-button>
          <el-button type="primary" @click="submit">确定</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>
import {listRedeemCode, createRedeemCode, deleteRedeemCode} from "@/api/redeemCode";

export default {
  name: 'RedeemCode',
  data() {
    return {
      title: '',
      redeemCodeList: [],
      form: {count: 1},
      openGameType: false
    }
  },
  methods: {

    handleDelete(row) {
      deleteRedeemCode(row.id).then(res => {
        listRedeemCode(null).then(resp => {
          this.redeemCodeList = resp.data.data
          this.openGameType = false
        })
      })
    },

    submit() {

      createRedeemCode(this.form.count).then(res => {
        listRedeemCode(null).then(resp => {
          this.redeemCodeList = resp.data.data
          this.openGameType = false
        })
      })

      this.form = {count: 1}
    },
    handleAdd() {
      this.title = '生成兑换码'
      this.openGameType = true
    }
  },
  created() {
    listRedeemCode(null).then(resp => {
      this.redeemCodeList = resp.data.data
    })
  }
}

</script>

<style scoped>

</style>
