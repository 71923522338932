import request from "@/util/request";

export function listRedeemCode(code) {
    return request({
        url: '/redeemCode/list',
        method: 'get',
        params: {
            code: code
        }
    })
}

export function createRedeemCode(count) {
    return request({
        url: '/redeemCode/create',
        method: 'get',
        params: {
            count: count
        }
    })
}

export function deleteRedeemCode(id) {
    return request({
        url: '/redeemCode/delete',
        method: 'get',
        params: {
            id: id
        }
    })
}

