// 路由配置
// 本后台系统目前路由简单，使用静态配置

import Game from "@/components/game";
import {createRouter, createWebHashHistory} from "vue-router";
import Doc from "@/components/Doc";
import HelloWorld from "@/components/HelloWorld";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import NotFound from "@/components/404.vue"
import Login from "@/components/Login";
import Profile from "@/components/Profile";
import User from "@/components/User";
import Knowledge from "@/components/Knowledge";
import Sample from "@/components/Sample";
import Scheme from "@/components/Scheme";
import Member from "@/components/Member"
import Product from "@/components/Product";
import ProductShowType from "@/components/ProductShowType";
import ProductHome from "@/components/ProductHome";
import ProductSeries from "@/components/ProductSeries";
import Notice from "@/components/Notice";
import Seo from "@/components/Seo";
import GameType from "@/components/GameType";
import BoxGameType from "@/components/BoxGameType";
import BoxGame from "@/components/BoxGame";
import RedeemCode from "@/components/RedeemCode";

const route = [
    {
        path: '/',
        component: HelloWorld,
        children: [
            {path: '/', component: User},
            {path: '/game', component: Game},
            {path: '/gameType', component: GameType},
            {path: '/doc', component: Doc},
            {path: '/knowledge', component: Knowledge},
            {path: '/profile', component: Profile},
            {path: '/user', component: User},
            {path: '/sample', component: Sample},
            {path: '/scheme', component: Scheme},
            {path: '/customer', component: Game},
            {path: '/notice', component: Notice},
            {path: '/product', component: Product},
            {path: '/productShowType', component: ProductShowType},
            {path: '/product-home', component: ProductHome},
            {path: '/product-series', component: ProductSeries},
            {path: '/seo', component: Seo},
            {path: '/member', component: Member},
            {path: '/boxGameType', component: BoxGameType},
            {path: '/boxGame', component: BoxGame},
            {path: '/redeemCode', component: RedeemCode}
        ]
    },
    {path: '/login', component: Login},
    {path: '/404', component: NotFound},
    {path: '/:pathMatch(.*)', redirect: '/404'}
];

const router = createRouter({
    // history: createWebHistory(),
    history: createWebHashHistory(),
    routes: route
})
NProgress.configure({showSpinner: false})
router.beforeEach((to, from, next) => {
    NProgress.start();
    next();
});

router.afterEach(() => {
    NProgress.done();
})

export default router;
